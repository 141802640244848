<template>
  <div>
    <b-sidebar id="sidebar-footer_search" aria-label="Sidebar with search users" shadow backdrop width="17%">
      <template #header>
        <b-input-group size="sm" class="mb-2">
          <b-form-input type="search" placeholder="搜索用户" v-model="query" debounce="500"></b-form-input>
          <!-- <b-input-group-prepend is-text v-b-toggle.sidebar-footer_search>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend> -->
        </b-input-group>
      </template>
      <template #footer="{ hide }">
        <div class="d-flex text-light align-items-center px-3 py-2" style="background: #2c3e50">
          <b-button id="closeButton" size="sm" @click="hide">取消</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div style="cursor: pointer;">
          <b-row style="padding: 6px 0px" v-for="(item, index) in results" :key="index" @click="onResult(item)">
            <b-col col lg="3" style="padding-right: 0px">
              <b-avatar :src="userAvatarImg"></b-avatar>
              <!-- <b-avatar
                badge
                badge-top
                badge-variant="success"
                :src="item.avatar"
              ></b-avatar> -->
            </b-col>

            <b-col col lg="9">
              <p class="searchUserName" style="padding-top: 10px">
                {{ getActiveName(item) }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import eventBus from "../assets/js/eventBus";
export default {
  name: "SearchUser",
  data() {
    return {
      query: "",
      results: [],
      avatorImg: require("../assets/img/chat_files/avator.png"),
      userAvatarImg: "https://wycy.oss-ap-southeast-1.aliyuncs.com/chat-space/f6625c68fa7e45adbcf7c8a91d33ec17.png"
    };
  },
  watch: {
    query: function (val) {
      this.searchUser(val);
    },
  },
  methods: {
    searchUser(query) {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "app/Chat/CustomerAgent/getUsers",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            value: query
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            let data = res.data.data;
            that.results = data;
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    onResult(item) {
      const storedChat = this.$store.state.message_list.find(i => i.id == item.id)
      if (!storedChat) {
        this.$store.commit('addNewAgent', {
          id: item.id,
          agentMessageList: [],
          count: 0,
          name: item.name,
          account:item.number,
          number: item.id.toString(),
          real_name: item.real_name
        })
      }
      this.getUserHistory(item, storedChat);
      document.getElementById("closeButton").click();
    },
    getUserHistory(chat, storedChat) {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/getHistory",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: that.$store.state.number
          }
          ,
        })
        .then((res) => {
          if (res.data.status == 200) {
            let data = res.data;
            data.data.forEach((element) => {
              this.$store.state.message_list.forEach((e) => {
                if (e.number == element.receiver || e.number == element.sender) {
                  const messagePresent = e.agentMessageList.find(message => message.order_id == element.order_id);
                  if (!messagePresent) {
                    e.agentMessageList.push(element);
                  }
                }
              })
            })

            // Get stored chat for the searched
            const chatStore = storedChat ? storedChat : this.$store.state.message_list.find(i => i.id == chat.id)
            eventBus.$emit("openSearchChat", chatStore)
            // that.$store.commit('updateSpecificAgentMessageList', {userNumber: number, newMessages: tempArray});
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }

        }).catch((e)=>{
          console.error(e.message);
        });
    },
    getActiveName(selectedUser) {
      if (!selectedUser.real_name) {
        return selectedUser.number
      }
      return `${selectedUser.number}-${selectedUser.real_name}`
    },
  },
  mounted: function () {
    let that = this;
    eventBus.$on("onShowSearchPop", (data) => {
      that.query = "";
      that.results = [];
    });
    this.query = "";
  },
};
</script>
<style scoped>
.searchUserName {
  text-align: left;
  padding-top: 17px;
  margin: 0;
  color: #2c3e50;
}
</style>

